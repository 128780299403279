import React from 'react'
import { Checkbox, FormControlLabel, FormControl, CheckboxProps } from '@material-ui/core'
import { FieldRenderProps } from 'react-final-form'

interface ICheckbox extends FieldRenderProps<any, HTMLElement>, Partial<CheckboxProps> {
  label: string
  size: 'small' | 'medium'
  color?: 'primary' | 'secondary' | 'default'
}
const UncontrolledCheckbox = React.forwardRef(
  (
    {
      id,
      name,
      checked,
      onChange,
      onClick,
      disabled,
      inputRef,
      label,
      size,
      error,
      color,
      indeterminate,
      ...props
    }: ICheckbox,
    ref
  ) => {
    return (
      <FormControl error={error}>
        <FormControlLabel
          control={
            <Checkbox
              id={id}
              name={name}
              checked={checked}
              onChange={onChange}
              onClick={onClick}
              disabled={disabled}
              size={size || 'small'}
              color={color || 'primary'}
              indeterminate={indeterminate}
              {...props}
            />
          }
          label={label}
          ref={inputRef}
        />
      </FormControl>
    )
  }
)

export default UncontrolledCheckbox
