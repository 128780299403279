import React, { SyntheticEvent, useEffect } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { useErrors } from 'hooks'
import { capitalize } from 'helpers'

interface ISelect extends FieldRenderProps<any, HTMLElement> {
  label: string
  disableDefaultValue?: boolean
  isDisabledSelectOption?: boolean
}
const Select = ({
  id,
  input,
  meta,
  inputType,
  disabled,
  className,
  tabIndex,
  autoComplete,
  onChange: onChangeSelect,
  label,
  options,
  error,
  variant,
  size,
  disableDefaultValue = false,
  isDisabledSelectOption = false,
}: ISelect) => {
  const { name, value, onClick, onChange, onBlur, onFocus } = input
  const { error: metaError, touched } = meta
  const { getFieldError } = useErrors()
  const fieldError = getFieldError(name)
  const formError = metaError || fieldError || ''
  const [isSelectChanged, setIsSelectChanged] = React.useState(false)

  useEffect(() => {
    setIsSelectChanged(isDisabledSelectOption && value)
  }, [options, isDisabledSelectOption, value])

  return (
    <>
      <TextField
        type={inputType}
        id={id}
        disabled={disabled}
        name={name}
        onClick={onClick}
        onChange={(e: SyntheticEvent) => {
          onChange(e)
          onChangeSelect && onChangeSelect(e)
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value || ''}
        label={label}
        SelectProps={{
          native: true,
        }}
        className={className}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        helperText={touched && capitalize(formError)}
        error={Boolean(touched && (error || formError))}
        size={size || 'small'}
        variant={variant || 'standard'}
        InputLabelProps={{
          shrink: true,
        }}
        select
        fullWidth
      >
        {!disableDefaultValue && !isDisabledSelectOption && (
          <option
            disabled={isDisabledSelectOption && isSelectChanged && options.length > 0}
            value={''}
          >
            Selecione
          </option>
        )}
        {options &&
          options.map((option: HTMLOptionElement) => (
            <option value={option.value} key={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
      </TextField>
    </>
  )
}

export default Select
