import React from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { FieldRenderProps } from 'react-final-form'
import { capitalize } from 'helpers'
import { useErrors } from 'hooks'

const DatePicker = ({
  id,
  input,
  meta,
  disabled,
  label,
  helperText,
  onChange: onChangeInput,
  error,
  size,
  variant,
  fullWidth,
  disablePast,
  disableFuture,
  maxDate,
}: FieldRenderProps<any, HTMLElement>) => {
  const { name, value, onChange, onBlur, onFocus } = input
  const { error: metaError, touched } = meta
  const { getFieldError } = useErrors()
  const fieldError = getFieldError(name)
  const formError = metaError || helperText || fieldError || ''

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        variant={variant || 'inline'}
        id={id}
        disabled={disabled}
        name={name}
        onChange={(date) => {
          onChange(date)
          onChangeInput && onChangeInput(date)
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value === '' ? null : value}
        label={label}
        helperText={touched && capitalize(formError)}
        error={Boolean(touched && (error || formError))}
        format='DD/MM/YYYY'
        InputLabelProps={{
          shrink: true,
        }}
        size={size || 'small'}
        fullWidth={fullWidth || true}
        disablePast={disablePast}
        disableFuture={disableFuture}
        maxDate={maxDate}
        autoOk
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
