import React, { Fragment, useMemo, useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  AccountCircle,
  Search,
  Dehaze,
  ExitToApp,
  Close,
  AssignmentInd,
  Assignment,
  SwapVert,
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { TLayout } from 'types'
import { capitalize, getFirstLetters } from 'helpers'
import { ENTITIES } from 'interfaces'
import { useCore } from 'hooks'
import { EntityDescription } from 'constants/entity'
import DefaultEntity from 'app/Default'
import { DropdownItem } from 'components/Dropdown'

import Dialog from './Dialog'
import UserAvatar from '../UserAvatar'
import {
  Navbar as NavbarComponent,
  Mobile,
  Desktop,
  Container,
  MobileLogo,
  SidePanelButton,
  ArrowDown,
  Dropdown,
  Filter,
} from './styles'

const Navbar: React.FC<TLayout> = ({ setIsOpened }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl] = useState<Element>()
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
  const { entity, logout, resetEntity, functions, setEntity } = useCore()
  const history = useHistory()
  const Logo = entity.logo.logoMin
  const hasFilter = document.getElementById('navbar-filter')?.innerHTML !== ''
  const entityCompanyName = entity.company ? entity.company.nome_fantasia : ''
  const entityName = entity.funcao
    ? entity.funcao?.entidade?.nome
        .replace(
          /(\[.*\])|(\(.*\))|(Canal Venda (.*?) -)|(\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2} -)/g,
          ''
        )
        .trim()
    : ''
  const subtitle =
    entity.id === 'employee'
      ? capitalize(
          (entity.funcao?.entidade.nome.match(/(\[.*\])/) &&
            (entity.funcao?.entidade?.nome?.match(/(\[.*\])/) || [])[0]?.replace(/(\[|\])/g, '')) ||
            ''
        )
      : EntityDescription[entity.funcao?.entidade.tipo || '']

  const isCorban = useMemo(() => {
    return entity.id === 'corban'
  }, [entity])

  const isShowChangeProfile = useMemo(() => {
    return functions.length > 1 || entity.id === ENTITIES.CORBAN
  }, [functions, entity])

  return (
    <NavbarComponent id='navbar'>
      <Container px={3}>
        <Mobile>
          <SidePanelButton onClick={setIsOpened}>
            <Dehaze fontSizeAdjust={32} />
          </SidePanelButton>
          {Logo && (
            <MobileLogo>
              <Logo height={25} />
            </MobileLogo>
          )}
        </Mobile>
        <Box
          display='flex'
          justifyContent={matches ? (showFilter ? 'center' : 'flex-end') : 'space-between'}
          alignItems='center'
          width='100%'
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent={matches ? (showFilter ? 'center' : 'flex-end') : 'space-between'}
            width={matches ? (showFilter ? '100%' : 0) : 'auto'}
          >
            <Filter show={showFilter} id='navbar-filter' />
            {showFilter && (
              <Box ml={2} display='flex' alignItems='center'>
                <Close onClick={() => setShowFilter(false)} />
              </Box>
            )}
          </Box>
          <Box alignItems='center' justifyContent='flex-end' display='flex' overflow='hidden'>
            <Desktop>
              {entity.funcao ? (
                <Fragment>
                  <Box
                    component={'div'}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <Box mr={2}>
                      <Box fontWeight='fontWeightBold' textAlign='right' display='block'>
                        {entityCompanyName ? entityCompanyName : entityName}
                      </Box>
                      <Typography align='right'>{subtitle}</Typography>
                    </Box>
                    <UserAvatar>
                      {getFirstLetters(entityCompanyName ? entityCompanyName : entityName)}
                    </UserAvatar>
                    <ArrowDown />
                  </Box>
                  <Dropdown
                    id='simple-menu'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(undefined)}
                    PaperProps={{
                      style: {
                        minWidth: '40px',
                      },
                    }}
                  >
                    {isShowChangeProfile && (
                      <DropdownItem
                        onClick={() => {
                          resetEntity()
                          history.push(DefaultEntity.route.PROFILES.path)
                        }}
                      >
                        <SwapVert />
                        <Typography>Trocar de perfil</Typography>
                      </DropdownItem>
                    )}
                    {isCorban && (
                      <Box component={'div'}>
                        <DropdownItem
                          onClick={() => {
                            setEntity((prevState) => ({
                              ...DefaultEntity,
                              funcao: prevState.funcao,
                              funcoes: prevState.funcoes,
                              // module: prevState.module,
                            }))
                            history.push(DefaultEntity.route.PROFILES.path)
                          }}
                        >
                          <SwapVert />
                          <Typography>Trocar de Corban</Typography>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setEntity((prevState) => ({
                              ...DefaultEntity,
                              funcao: prevState.funcao,
                              funcoes: prevState.funcoes,
                              module: {
                                mainModule: prevState.module?.mainModule!,
                                subModule: undefined,
                              },
                            }))
                            // resetEntity()
                            history.push(DefaultEntity.route.PROFILES.path)
                          }}
                        >
                          <SwapVert />
                          <Typography>Trocar de Módulo</Typography>
                        </DropdownItem>
                      </Box>
                    )}
                    <DropdownItem
                      onClick={() => {
                        history.push(`${entity.path}${entity.route.MY_ACCOUNT?.path}`)
                        setAnchorEl(undefined)
                      }}
                    >
                      <AssignmentInd />
                      <Typography>Minha Conta</Typography>
                    </DropdownItem>
                    {entity.id === 'employee' && (
                      <DropdownItem
                        onClick={() => {
                          history.push(`${entity.path}${entity.route.USER_TERMS.path}`)
                          setAnchorEl(undefined)
                        }}
                      >
                        <Assignment />
                        <Typography>Termos de uso</Typography>
                      </DropdownItem>
                    )}
                    <DropdownItem
                      onClick={() => {
                        logout()
                        history.push('')
                      }}
                    >
                      <ExitToApp color='error' />
                      <Typography color='error'>Sair</Typography>
                    </DropdownItem>
                  </Dropdown>
                </Fragment>
              ) : (
                <>
                  <Box mr={2}>
                    <Skeleton animation='wave' height={20} width={100} />
                    <Skeleton animation='wave' height={15} width={100} />
                  </Box>
                  <Skeleton animation='wave' variant='circle' width={40} height={40} />
                </>
              )}
            </Desktop>
            <Mobile border>
              {!showFilter && (
                <>
                  <AccountCircle onClick={() => setIsDialogOpened(true)} />
                  {hasFilter && <Search onClick={() => setShowFilter(true)} />}
                  <Dialog
                    name={entityName}
                    module={subtitle}
                    isOpen={isDialogOpened}
                    onClose={() => setIsDialogOpened((prevState) => !prevState)}
                    isShowChangeProfile={isShowChangeProfile}
                  />
                </>
              )}
            </Mobile>
          </Box>
        </Box>
      </Container>
    </NavbarComponent>
  )
}

export default Navbar
