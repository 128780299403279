import { IRouteCodes } from 'interfaces'
import LazyLoading from 'components/LazyLoading'
import {
  Receipt,
  RestorePageOutlined,
  AttachMoney,
  InsertDriveFile,
  Description,
  PersonOutlineOutlined,
  AssignmentInd,
} from '@material-ui/icons'
import { routeCodes, subRouteCodes } from 'constants/routes'

const Location = LazyLoading(() => import('../Default/pages/Location'))
const ProposalLayout = LazyLoading(() => import('./pages/Proposal'))
const ProposalList = LazyLoading(() => import('./pages/Proposal/List'))
const ProposalDetails = LazyLoading(() => import('./pages/Proposal/Resume'))
const ContractLayout = LazyLoading(() => import('./pages/Contracts'))
const ContractList = LazyLoading(() => import('./pages/Contracts/List'))
const ContractView = LazyLoading(() => import('./pages/Contracts/View'))
const Redirect404 = LazyLoading(() => import('components/Redirect404'))
const MyAccount = LazyLoading(() => import('app/Company/pages/MyAccount'))
const MyAccountList = LazyLoading(() => import('app/Company/pages/MyAccount/List'))
const MyAccountChangeMail = LazyLoading(() => import('app/Company/pages/MyAccount/ChangeMail'))
const MyAccountChangePassword = LazyLoading(() =>
  import('app/Company/pages/MyAccount/ChangePassword')
)
const HireCredit = LazyLoading(() => import('./pages/HireCredit'))
const Simulate = LazyLoading(() => import('./pages/HireCredit/Simulate'))
const SimulateView = LazyLoading(() => import('./pages/HireCredit/View'))
const HireCreditWorkCapital = LazyLoading(() => import('./pages/HireCreditWorkCapital'))
const SimulateWorkingCapital = LazyLoading(() => import('./pages/HireCreditWorkCapital/Simulate'))
const VerifyIdentityWorkingCapital = LazyLoading(() =>
  import('./pages/HireCreditWorkCapital/VerifyIdentity')
)
const ResumeWorkingCapital = LazyLoading(() => import('./pages/HireCreditWorkCapital/Resume'))
const RegisterWorkingCapital = LazyLoading(() => import('./pages/HireCreditWorkCapital/Register'))
const VerifyIdentity = LazyLoading(() => import('./pages/HireCredit/VerifyIdentity'))
const Register = LazyLoading(() => import('./pages/HireCredit/Register'))
const Renegotiate = LazyLoading(() => import('./pages/Renegotiate'))
const RenegotiateVerify = LazyLoading(() => import('./pages/Renegotiate/VerifyIdentity'))
const RenegotiateList = LazyLoading(() => import('./pages/Renegotiate/List'))
const RenegotiateSimulate = LazyLoading(() => import('./pages/Renegotiate/Simulate'))
const RenegotiateConfirmData = LazyLoading(() => import('./pages/Renegotiate/ConfirmData'))
const RenegotiateResume = LazyLoading(() => import('./pages/Renegotiate/Resume'))
const MyAccountChangePhone = LazyLoading(() => import('app/Company/pages/MyAccount/ChangePhone'))
const MyAccountToken = LazyLoading(() => import('app/Company/pages/MyAccount/Token'))
const OperatorsLayout = LazyLoading(() => import('./pages/Operator'))
const OperatorsList = LazyLoading(() => import('./pages/Operator/List'))
const OperatorsCreateEdit = LazyLoading(() => import('./pages/Operator/Form'))
const OperatorsView = LazyLoading(() => import('./pages/Operator/View'))
const AccessProfileLayout = LazyLoading(() => import('../Default/pages/AccessProfile'))
const AccessProfileList = LazyLoading(() => import('../Default/pages/AccessProfile/List'))
const AccessProfileCreateEdit = LazyLoading(() => import('../Default/pages/AccessProfile/Form'))

export const DefaultRoutes: IRouteCodes = {
  DEFAULT: {
    path: routeCodes.NULL_ROUTE,
    name: 'Página não encontrada',
    component: Redirect404,
    hideInSidePanel: true,
  },
  HIRE_CREDIT: {
    path: routeCodes.LEGAL_PERSON.HIRE_CREDIT,
    name: 'Solicitar Desconto de Recebíveis',
    icon: InsertDriveFile,
    component: HireCredit,
    defaultRoute: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Simulação de desconto de recebíveis',
        component: Simulate,
      },
      VIEW: {
        path: subRouteCodes.VIEW,
        name: 'Simulação de desconto de recebíveis',
        component: SimulateView,
      },
      VERIFY_IDENTITY: {
        path: subRouteCodes.LEGAL_PERSON.HIRE_CREDIT.VERIFY_IDENTITY,
        name: 'Identificar',
        component: VerifyIdentity,
      },
      REGISTER: {
        path: subRouteCodes.LEGAL_PERSON.HIRE_CREDIT.REGISTER,
        name: 'Registrar cliente',
        component: Register,
      },
    },
  },
  HIRE_CREDIT_WORKING_CAPITAL: {
    path: routeCodes.LEGAL_PERSON.HIRE_CREDIT_WORKING_CAPITAL,
    name: 'Solicitar Capital de Giro',
    icon: AttachMoney,
    component: HireCreditWorkCapital,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Simulação de capital de giro',
        component: SimulateWorkingCapital,
      },
      VERIFY_IDENTITY: {
        path: subRouteCodes.LEGAL_PERSON.HIRE_CREDIT_WORKING_CAPITAL.VERIFY_IDENTITY,
        name: 'Identificar',
        component: VerifyIdentityWorkingCapital,
      },
      REGISTER: {
        path: subRouteCodes.LEGAL_PERSON.HIRE_CREDIT_WORKING_CAPITAL.REGISTER,
        name: 'Registrar cliente',
        component: RegisterWorkingCapital,
      },
      RESUME: {
        path: subRouteCodes.LEGAL_PERSON.HIRE_CREDIT_WORKING_CAPITAL.RESUME,
        name: 'Registrar cliente',
        component: ResumeWorkingCapital,
      },
    },
  },
  RENEGOTIATE: {
    path: routeCodes.LEGAL_PERSON.RENEGOTIATE,
    name: 'Renegociar Capital de Giro',
    icon: RestorePageOutlined,
    component: Renegotiate,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Renegociar Capital de Giro',
        component: RenegotiateVerify,
      },
      LIST: {
        path: subRouteCodes.LIST,
        name: 'Renegociar Capital de Giro',
        component: RenegotiateList,
      },
      SIMULATE: {
        path: subRouteCodes.LEGAL_PERSON.RENEGOTIATE.SIMULATE,
        name: 'Renegociar Capital de Giro',
        component: RenegotiateSimulate,
      },
      CONFIRM_DATA: {
        path: subRouteCodes.LEGAL_PERSON.RENEGOTIATE.CONFIRM_DATA,
        name: 'Atualizar dados',
        component: RenegotiateConfirmData,
      },
      RESUME: {
        path: subRouteCodes.LEGAL_PERSON.RENEGOTIATE.RESUME,
        name: 'Resumo',
        component: RenegotiateResume,
      },
    },
  },
  LOCATION: {
    path: routeCodes.LEGAL_PERSON.LOCATION,
    name: 'Habilitar localização',
    component: Location,
  },
  PROPOSALS: {
    path: routeCodes.LEGAL_PERSON.PROPOSAL,
    name: 'Propostas',
    icon: Description,
    component: ProposalLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Aprovadas',
        component: ProposalList,
      },
      VIEW: {
        path: '/:id',
        name: 'Detalhes da proposta',
        component: ProposalDetails,
        hideInSidePanel: true,
      },
      CANCELED: {
        path: subRouteCodes.LEGAL_PERSON.PROPOSALS.CANCELATED,
        name: 'Canceladas',
        component: ProposalList,
      },
      DENIED: {
        path: subRouteCodes.LEGAL_PERSON.PROPOSALS.DENIED,
        name: 'Negadas',
        component: ProposalList,
      },
      // @TODO: Caso seja necessário posterioremente ter aba de pendencia de documentação
      // PENDING: {
      //   path: subRouteCodes.LEGAL_PERSON.PROPOSALS.PENDING_DOC,
      //   name: 'Pendente de documentação',
      //   component: ProposalList,
      // },
    },
  },
  CONTRACTS: {
    path: routeCodes.LEGAL_PERSON.CONTRACTS,
    name: 'Contratos',
    icon: Receipt,
    component: ContractLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Aprovadas',
        component: ContractList,
      },
      VIEW: {
        path: '/:id',
        name: 'Detalhes da proposta',
        component: ContractView,
        hideInSidePanel: true,
      },
      CANCELATED: {
        path: subRouteCodes.LEGAL_PERSON.CONTRACTS.CANCELATED,
        name: 'Cancelados',
        component: ContractList,
      },
      REVERSED: {
        path: subRouteCodes.LEGAL_PERSON.CONTRACTS.REVERSED,
        name: 'Estornados',
        component: ContractList,
      },
      EXPIRATED: {
        path: subRouteCodes.LEGAL_PERSON.CONTRACTS.EXPIRATED,
        name: 'Expirados',
        component: ContractList,
      },
      PENDING_SIGN: {
        path: subRouteCodes.LEGAL_PERSON.CONTRACTS.PENDING_SIGN,
        name: 'Pendente de assinatura',
        component: ContractList,
      },
      PENDING_TED: {
        path: subRouteCodes.LEGAL_PERSON.CONTRACTS.PENDING_TED,
        name: 'Pendente de TED',
        component: ContractList,
      },
    },
  },
  MY_ACCOUNT: {
    path: routeCodes.COMPANY.MY_ACCOUNT,
    name: 'Minha Conta',
    component: MyAccount,
    hideInSidePanel: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Minha Conta',
        component: MyAccountList,
      },
      CHANGE_MAIL: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_MAIL,
        name: 'Alterar email',
        component: MyAccountChangeMail,
      },
      CHANGE_PASSWORD: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PASSWORD,
        name: 'Alterar Senha',
        component: MyAccountChangePassword,
      },
      CHANGE_TELEPHONE: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PHONE,
        name: 'Alterar Telefone',
        component: MyAccountChangePhone,
      },
      TOKEN: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.TOKEN,
        name: 'Confirmar código',
        component: MyAccountToken,
      },
    },
  },
  OPERATORS: {
    path: routeCodes.SALES_CHANNEL.OPERATORS,
    name: 'Operadores',
    component: OperatorsLayout,
    icon: PersonOutlineOutlined,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Operadores',
        component: OperatorsList,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar operador',
        component: OperatorsView,
      },
      EDIT: {
        path: `${subRouteCodes.EDIT}/:id`,
        name: 'Editar Operador',
        component: OperatorsCreateEdit,
      },
      NEW: {
        path: subRouteCodes.NEW,
        name: 'Novo Operador',
        component: OperatorsCreateEdit,
      },
    },
  },
  ACCESS_PROFILE: {
    path: routeCodes.DEFAULT.ACCESS_PROFILE,
    name: 'Perfis de acesso',
    component: AccessProfileLayout,
    icon: AssignmentInd,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Perfis de acesso',
        component: AccessProfileList,
      },
      VIEW: {
        path: '/:id',
        name: 'Perfil de acesso',
        component: AccessProfileCreateEdit,
      },
      NEW: {
        path: subRouteCodes.NEW,
        name: 'Novo perfil de acesso',
        component: AccessProfileCreateEdit,
      },
    },
  },
}

export default DefaultRoutes
