import { ENTITY_ENUM } from 'interfaces'

export const EntityDescription: { [key: string]: string } = {
  [ENTITY_ENUM.COMPANY]: 'Gestor de empresa',
  [ENTITY_ENUM.EMPLOYEE]: 'Funcionário',
  [ENTITY_ENUM.SALES_CHANNEL]: 'Crédito Pessoal',
  [ENTITY_ENUM.CORBAN]: 'Corban',
  [ENTITY_ENUM.CDC]: 'CDC',
  [ENTITY_ENUM.CDCVEICULOS]: 'CDC Veículos',
  [ENTITY_ENUM.PJ]: 'Pessoa Jurídica',
  [ENTITY_ENUM.CLIENT_CP]: 'Autoatendimento FGTS',
  [ENTITY_ENUM.SUPERVISOR]: 'Supervisor Convênio',
}

export const permissionsKeyByEntity: { [key: string]: string } = {
  [ENTITY_ENUM.COMPANY]: 'empresa.gestor',
  [ENTITY_ENUM.SALES_CHANNEL]: 'canalvenda.gestor',
  [ENTITY_ENUM.CORBAN]: 'corban.operador',
  [ENTITY_ENUM.CDC]: 'canalvendacdc.gestor',
  [ENTITY_ENUM.CDCVEICULOS]: 'canalvendacdcveiculo.gestor',
  [ENTITY_ENUM.PJ]: 'canalvendapj.gestor',
}
