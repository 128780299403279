import { IEmployee } from './employee'
import { ICompany } from './company'
import { IFinancial } from './financial'
import { IContractInfo } from './contractInfo'
import { IParcelsInfo, IParcelaRenegociacao } from './parcelsInfo'
import { ISignInfo } from './signInfo'
import { DocumentsKeys, ICovenant, IDocuments, ILegalPerson, IPayment } from 'interfaces'
import { IMetadados } from './metadados'
import { IDisposal } from './disposal'
import IInsurer from './insurer'

export interface IAgentCertificado {
  id: string
  nome: string
  sobrenome: string
  email: string
  cpf: string
}

export interface IContract {
  id: string
  cpf: string
  cpf_operador: string
  nome_operador: string
  corban: string
  canal_atendimento: string
  financeira: IFinancial
  averbar_ate: string
  cliente: ILegalPerson
  cliente_info_no_contrato: IContractInfo
  status: string
  data_primeiro_vencimento: string
  data_ultimo_vencimento: string
  ultimo_vencimento: string
  gerado_em: string
  data_efetivacao: string
  data_pagamento: string
  proposta: string
  taxa_contrato: string
  taxa_irr: string
  taxa_cet_mes: string
  taxa_iof: string
  taxa_iof_complementar: string
  taxa_multa: string
  dias_cobranca_multa: string
  valor_liberado: number
  valor_iof: number
  valor_tarifas: number
  valor_juros: number
  valor_recebivel: number
  valor_seguro: number
  valor_total_devido: number
  valor_financiado: number
  valor_parcela: number
  valor_solicitado: number
  prazo: number
  data_solicitacao: string
  modalidade: string
  metadados: IMetadados
  promotora: string
  funcionario: IEmployee
  status_averbacao: string
  empresa: ICompany
  origem: string
  taxa_permanencia: number
  ccb_original_html: string
  financeira_valor_a_pagar: string
  ccb_original_pdf: string
  protocolo_digital_pdf: string
  saldo_devedor: number
  parcelas_info: IParcelsInfo
  localizador: string
  assinatura_info: ISignInfo
  convenio: ICovenant
  telefone_celular: string
  email: string
  data_averbacao: string
  nome: string
  sobrenome: string
  data_nascimento: string
  nacionalidade: string
  sexo: string
  estado_civil: string
  nome_mae: string
  telefone_fixo: string
  cargo: string
  pagamento__banco: string
  pagamento__nome_banco: string
  pagamento__agencia: string
  pagamento__agencia_dac: string
  pagamento__conta: string
  pagamento__conta_dac: string
  pendente_ted: boolean
  status_descricao: string
  valor_a_liquidar: number
  recebiveis_em_renegociacao: IParcelaRenegociacao[]
  migrado_em: string
  // CDC Vehicle
  alienacao: IDisposal
  entrada: number
  agente_certificado: IAgentCertificado
  pagamento: IPayment
  cnpj: string
  nome_fantasia: string
  seguradora: IInsurer
  descricao_bem_servico: string
  termo_antecipacao_original_pdf: string
  documentacao_comprovacao: {
    [DocumentsKeys.IDENTITY_FRONT]?: IDocuments
    [DocumentsKeys.IDENTITY_REVERSE]?: IDocuments
    [DocumentsKeys.INCOME]?: IDocuments
    [DocumentsKeys.RESIDENCE]?: IDocuments
  }
}
